<template>
  <div class="page-content product-page">
    <div class="product-page-content">
      <CommonTabView :column="2" :data="productData" detail-router="ProductDescription" v-if="productData.length" />
      <div class="page-content-placeholder" v-else>暂无产品信息</div>
    </div>
  </div>
</template>

<script>
import CommonTabView from '../components/CommonTabView'
import { description, keywords } from '../data/meta'

export default {
  name: 'Product',
  metaInfo: {
    title: '零方科技 | 产品介绍',
    meta: [
      { name: 'description', content: description },
      { name: 'keywords', content: keywords + ',产品介绍，经营宝,生意宝,开店宝,会员产品,办公产品,金融产品,生活产品,物流产品,医药产品,商贸产品,外卖产品,智能零售,生产产品,财务产品,外送产品,数据产品' }
    ]
  },
  components: {
    CommonTabView
  },
  inject: ['product'],
  computed: {
    productData() {
      return this.product()
    }
  }
}
</script>

<style lang="less" scoped>
.product-page {
  width: 100%;
  height: 100vh;
  padding-top: 100px;
  background: url("../assets/img/plan_bg.jpg") no-repeat fixed;
  background-size: cover;
  color: #FFFFFF;
  .product-page-content {
    width: 100%;
    padding: 80px 0;
    height: calc(100vh - 100px);
    overflow-y: scroll;
    .slogan-area {
      width: 1200px;
      margin: 0 auto 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .slogan-title {
        font-size: 40px;
      }
      .slogan-desc {
        width: 645px;
        margin-top: 20px;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
      }
    }
    .page-content-placeholder {
      width: 100%;
      height: 100%;
      color: rgba(255, 255, 255, 0.3);
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
