<template>
  <div class="page-content product-detail-page">
    <div class="detain-content" v-html="detailHtml" />
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ProductDetail',
  data() {
    return {
      detailHtml: ''
    }
  },
  methods: {
    getDetailData(id) {
      if (!id) return
      const self = this
      axios.get('/api/Open/GetProductDetail', { params: { pdtId: id } })
        .then(response => {
          response = response.data
          if (response.Code === '0') {
            self.detailHtml = response.Data.Detail
            document.title = `零方科技 | ${response.Data.ShortName}`
          }
        })
    }
  },
  created() {
    this.getDetailData(this.$route.query.id)
  }
}
</script>

<style lang="less" scoped>
.product-detail-page {
  width: 100%;
  padding-top: 100px;
  min-height: calc(100vh - 342px);
  //.card-list {
  //  width: 1200px;
  //  margin: 40px auto;
  //  .detail-card {
  //    margin-bottom: 30px;
  //    &:last-child {
  //      margin-bottom: 0;
  //    }
  //  }
  //}
}
</style>

<style scoped>
.product-detail-page >>> p,
ul,
menu,
dir,
h3 {
  max-width: 100%;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.product-detail-page >>> h1 {
  max-width: 100%;
  font-weight: bold;
  font-size: 32px;
  margin: 21px 0;
}
.product-detail-page >>> h2 {
  max-width: 100%;
  font-weight: bold;
  font-size: 24px;
  margin: 19px 0;
}
.product-detail-page >>> h3 {
  max-width: 100%;
  font-weight: bold;
  font-size: 19px;
  margin: 18px 0;
}
.product-detail-page >>> h4 {
  max-width: 100%;
  font-weight: bold;
  font-size: 16px;
  margin: 21px 0;
}
.product-detail-page >>> h5 {
  max-width: 100%;
  font-weight: bold;
  font-size: 13px;
  margin: 22px 0;
}
.product-detail-page >>> h6 {
  max-width: 100%;
  font-weight: bold;
  font-size: 11px;
  margin: 24px 0;
}
.product-detail-page >>> img {
  max-width: 100%;
}
.product-detail-page >>> option {
  padding: 0px 2px 1px;
}
.product-detail-page >>> input {
  padding: 1px;
}
.product-detail-page >>> fieldset {
  margin-inline-start: 2px;
  margin-inline-end: 2px;
  padding-block-start: 0.35em;
  padding-inline-start: 0.75em;
  padding-inline-end: 0.75em;
  padding-block-end: 0.625em;
}
.product-detail-page >>> textarea {
  padding: 2px;
}
.product-detail-page >>> legend {
  padding-inline-start: 2px;
  padding-inline-end: 2px;
}
.product-detail-page >>> a {
  /*text-decoration: underline;*/
}
.product-detail-page >>> ul {
  padding-left: 40px;
  padding-right: 40px;
  list-style: unset;
}
.product-detail-page >>> ol {
  list-style: decimal;
  padding-left: 40px;
  padding-right: 40px;
}
.product-detail-page >>> li {
  display: list-item;
  list-style: unset;
}
.product-detail-page >>> p {
  max-width: 100%;
  min-height: 1.5em;
}
</style>
